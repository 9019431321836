import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service'; 

@Injectable({
  providedIn: 'root'
})

export class AddressService {

  
  private path = '/address';


  constructor(private backendService: BackendService) { }

   
 
  
  

  saveAddress(address){
    return this.backendService.post(this.path , address);
  }

  getAddress(cid){
    
    return this.backendService.get(this.path + '/entities/' +  cid );
  }
  getAddressById(id){
    
    return this.backendService.get(this.path +  id );
  }
 

  deleteAddress(id) {
   
    return this.backendService.post(this.path + '/remove/'+ id, {});
  }
 
 

 

 
 


}