import { Component, OnInit } from '@angular/core';

//import { LoginService } from '../../../app/services/login.service';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/shared';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
  
})
export class LoginComponent{
 email:any;
 password: any;
  public errorMessage: any; 

  constructor(
    private authenticationService: AuthenticationService,
    private router:Router
   ) {
    
  }

  onRegister(){
    this.router.navigate (["pages/register"]);
  }

  public login (){
    this.errorMessage = "";
    if (!this.email){
      this.errorMessage = "Invalid email";
      return;
    }
    if (!this.password){
      this.errorMessage = "Invalid password";
      return;
    }


    this.authenticationService.login (this.email, this.password)
    .subscribe(res => {
      if (res["error"]){
        this.errorMessage = res["error"];
        return;
      }
      


      this.authenticationService.saveLogin (res);
      this.router.navigate (["/fee-schedule"]);
    });

  }

  onForgotpass (){
    this.router.navigate (['pages/forgot-pass']);
  }

  handleKeyOnPass (e){
    if (e.keyCode == 13){
      this.login ();
    }
  }

}
