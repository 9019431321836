import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service' ;

@Injectable({
  providedIn: 'root'
})
export class FeeScheduleService {

  private path = '/fee-schedule';

  constructor(private backendService : BackendService) { }

  saveFee(feeschedule){
    console.log("feeschedule>>>>>>>>>>>>>>",feeschedule);
    return this.backendService.post(this.path + '/', feeschedule );
  }

  deleteFee(id) {
    return this.backendService.post(this.path + '/delete/' + id , {});
  }

  searchFee(currPage:number, pageSize:number, filter:any) {   
    var data = {
      pagination: {
        page: currPage,
        size: pageSize
      },
      filter:filter
    };
    return this.backendService.post(this.path + "/search", data);
  }
  
  getFeebyId(id){
    return this.backendService.get(this.path + '/'+ id);
  }

  

}


