import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';

@Injectable({
    providedIn: 'root'
})

export class ProviderService {


    private path = '/providers';

    constructor(private backendService: BackendService) { }

    saveProvider(provider) {
        return this.backendService.post(this.path , provider);

    }

    deleteProvider(id){
        return this.backendService.post(this.path + '/delete/' + id , {});
    }

    getproviderbyId(id) {        
       
        return this.backendService.get(this.path + '/'+ id);
      }  

      searchProviderData(currPage:number, pageSize:number, filter:any) {
    
        var data = {
          pagination: {
            page: currPage,
            size: pageSize
          },
          filter:filter
        };
        return this.backendService.post(this.path + "/search", data);
      }

      saveProviderAddress(address) {
        return this.backendService.post(this.path + '/address', address);
      }  
    
      saveProviderContact(contact) {
        return this.backendService.post(this.path + '/contacts', contact);
      }
}


