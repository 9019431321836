import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';

@Injectable({
    providedIn: 'root'
})
export class AdjusterService {

    private path = '/adjuster';

    constructor(private backendService: BackendService,
    ) { }

    searchAdjusterData(currPage: number, pageSize: number, filter: any) {

        var data = {
            pagination: {
                page: currPage,
                size: pageSize
            },
            filter: filter
        };
        return this.backendService.post(this.path + "/search", data);
    }

    saveAdjuster(data) {
        return this.backendService.post(this.path, data);
    }
    saveAdjusteraddress(address) {
        return this.backendService.post(this.path + "/address",  address );
    }
    saveAdjusterContact(Contact) {

        return this.backendService.post(this.path + '/contact', Contact);
    }
    getAdjusterDataById(id) {
        return this.backendService.get(this.path + '/' + id);
    }

  deleteAdjusterData(AdjusterId) {

        return this.backendService.post(this.path + '/delete/' + AdjusterId , {});
      }
}


