import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import * as Dropzone from 'dropzone'; 
import { AuthenticationService } from '../services'; 



@Component({
  selector: 'app-rb-uploader',
  templateUrl: './rb-uploader.component.html',
  styleUrls: ['./rb-uploader.component.scss']
})
export class RbUploaderComponent implements OnInit {


  @Input() url:any;
  @Input() msg: any;
  @Input() doctype:any;

  @Input() contextId:any;
  @Input() contextType:any;

  public dropZone: any;
  @Input() dropZoneId: any ;// = "mydropzone";



  @Output() docUploaded = new EventEmitter<any>();
  initialized  = false;

  constructor(private authenticationService: AuthenticationService) { }

  ngOnInit() {
  
  }

  ngAfterViewInit (){
    if (!this.initialized && this.dropZoneId){ 
      this.dropZoneInit ();
    }
  }


  dropZoneInit() {

  var token  = this.authenticationService.getAuthToken ();


  this.dropZone = new Dropzone("#"+ this.dropZoneId, { 
    url: this.url,
    uploadMultiple: false,
    dictDefaultMessage: this.msg,
    maxFiles: 5, 
    headers: {
      contextid: this.contextId,
      contexttype: this.contextType,
      doctype:  this.doctype,
      token: token, 
      
    },
    accept:  (file, done) => { 
      done ();
    }
  });


   
  this.dropZone.on("success", (file, response) => { 
    if (response.documentid){
      this.docUploaded.emit (response);
    }else {
      alert  ("file upload failed..." + response.status);
    }
  }); 


  this.dropZone.on("complete", (file) => { 
    this.dropZone.removeFile (file);
  }); 


  this.initialized = true;


}


 
}
