import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})

export class EmailSettingService {

  
  private path = '/email-setting';
  private userSessionKey = 'user';

  constructor(private backendService: BackendService,
              private storageService: StorageService ) { }

  
  saveEmailSetting(name: string,description:string, hostname:string, port:string ,enable:boolean,toemail:string, fromemail:string, username:string, password:string) {
      
    return this.backendService.post(this.path + '/', {name: name,description:description,hostname:hostname,port:port,enable:enable, toemail:toemail, fromemail:fromemail,username:username,password:password });
  }
   
  getEmailSetting() {
      
    return this.backendService.get(this.path);
  }
 
}