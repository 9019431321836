import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { DocumentService, CoreService, AuthenticationService } from '../../services';

@Component({
  selector: 'app-doc-viewer',
  templateUrl: './doc-viewer.component.html',
  styleUrls: ['./doc-viewer.component.scss']
})
export class DocViewerComponent implements OnInit, OnChanges {

  @Input() document: any;
  
  viewerType= "";
  url  = "";

  constructor(private coreService:CoreService, private authenticationService:AuthenticationService) { }

  ngOnInit() {
  }

  initViwerType (){
    if (!this.document){
      this.viewerType = "";
      return;
    }
    var file  = this.document;
    if (file.extension == "doc" || file.extension == "docx"){
      this.viewerType = "download";
    }
    if (file.extension == "xls" || file.extension == "xlsx"){
      this.viewerType = "download";
    }
    if (file.extension == "ppt" || file.extension == "pptx"){
      this.viewerType = "download";
    }

    if (file.extension == "json"){
      this.viewerType = "iframe";
    }

    if (file.extension == "pdf"){
      this.viewerType = "iframe";
    }

    if (file.extension == "csv"){
      this.viewerType = "iframe";
    }

    if (file.mimetype.indexOf ("image") >=0){
      this.viewerType = "image";
    }

    if (!this.viewerType){
      this.viewerType = "iframe";
    }

   
  }

  ngOnChanges(){
    

    this.initViwerType ();
    if (this.document){
    this.url = this.coreService.getRootPath () + "/documents/id/"
             + this.document._id + "?token=" + this.authenticationService.getAuthToken ();
    }
  }


  
}
