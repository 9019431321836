import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';

@Injectable({
  providedIn: 'root'
})

export class EmployerService {

  
  private path = '/employers';

  constructor(private backendService: BackendService) { }

  
  saveEmployer(employer) {
      
    return this.backendService.post(this.path , employer);
  }

    
  loadAll() {
      
    return this.backendService.post(this.path + '/all', {});
  }
 
 
  searchEmployee(currPage:number, pageSize:number, filter:any) {   
    var data = {
      pagination: {
        page: currPage,
        size: pageSize
      },
      filter:filter
    };

    return this.backendService.post(this.path + "/search", data);
  }

  getemployerDatabyId(id){

    return this.backendService.get(this.path + '/'+ id);
  }


  deleteEmployee(id){
    return this.backendService.get(this.path + '/delete/'+ id);
  }

  saveEmployerAddress(employerAddress: any) {
    return this.backendService.post(this.path + '/address', employerAddress);
  }  

  saveEmployerContact(employerContact) {
    return this.backendService.post(this.path + '/contact', employerContact);
  }

}