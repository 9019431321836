import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';

@Injectable({
  providedIn: 'root'
})

export class CommentService {


  private path = '/comments'; //main route needs to be plural if it can.


  constructor(private backendService: BackendService) { }


  saveComment(bill:any) {
     //do not cretae object here .. {bill: bill} is wrong.
    return this.backendService.post(this.path , bill);
  }

  getComments (billid){
    //make suer put a "/" or your path will be wrong.
    return this.backendService.get(this.path + '/' +  billid );
  }

}