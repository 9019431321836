import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';

@Injectable({
  providedIn: 'root'
})

export class BillDetailService {

  
  private path = '/bills';


  constructor(private backendService: BackendService ) { }

   
  searchBillData(currPage:number, pageSize:number, filter:any) {
   
    var data = {
      pagination: {
        page: currPage,
        size: pageSize
      },
      filter:filter
    };
    return this.backendService.post(this.path + "/search", data);
  }

  latestBillData(){
    return this.backendService.get(this.path + "/latestbills");
  }
 
  
saveBillDetail(data){
  
  return this.backendService.post(this.path , data);
}

getBillData(id) {    
  return this.backendService.get(this.path + '/' + id);
}


deleteBill(billid) {
    
  return this.backendService.post(this.path + '/'+ billid + "/remove", {});
}

saveBillDates(data){
  
  return this.backendService.post(this.path + "/dates", data);
}

getBillDatesById(id) {    
  return this.backendService.get(this.path + '/dates/' + id);
}


getBillItems(id) {    
  
  return this.backendService.get(this.path  + "/"  + id + '/items');
}

getItemById(billid, id) {    
  
  return this.backendService.get(this.path + '/' + billid +  '/items/' + id);
}


saveAudit(item){
  return this.backendService.post(this.path  + '/' + item.billid +  '/items/' + item._id  + "/audit", item);
}

saveMedication(item){
  return this.backendService.post(this.path + "/items", item);
}

deleteMedication(id) {
  return this.backendService.post(this.path + '/items/'+ id + "/remove", {});
}

saveBillStatus(data){
  
  return this.backendService.post(this.path + "/status", data);
}



getBillTemplates(id) {    
  return this.backendService.get(this.path + '/' + id + "/templates");
}


saveDataEntry(data){
  
  return this.backendService.post(this.path + "/entry", data);
}

savePayment(data){
  return this.backendService.post(this.path + "/payment", data);
}

saveNotes (data){
  return this.backendService.post(this.path + "/" + data.billid + "/notes", data);
}


finalize (data){
  return this.backendService.post(this.path + "/" + data.billid + "/finalize", data);
}

}