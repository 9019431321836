import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';

@Injectable({
    providedIn: 'root'
})
export class DispenserService {

    private path = '/dispensers';

    constructor(private backendService: BackendService,
    ) { }

    searchDispenserData(currPage: number, pageSize: number, filter: any) {

        var data = {
            pagination: {
                page: currPage,
                size: pageSize
            },
            filter: filter
        };
        return this.backendService.post(this.path + "/search", data);
    }

    saveDispenser(data) {
        return this.backendService.post(this.path, data);
    }
    saveDispenseraddress(address) {
        return this.backendService.post(this.path + "/address",  address );
    }
    saveDispenserContact(dispenserContact) {

        return this.backendService.post(this.path + '/contact', dispenserContact);
    }
    getDispenserDataById(id) {
        return this.backendService.get(this.path + '/' + id);
    }

  deleteDispenserData(dispenserId) {

        return this.backendService.post(this.path + '/delete/' + dispenserId , {});
      }
}


