import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search-header',
  templateUrl: './search-header.component.html',
  styleUrls: ['./search-header.component.scss']
})
export class SearchHeaderComponent implements OnInit {


  @Input() entityName: any;
  @Input() nav: any;

  @Output() searchClick = new EventEmitter<any>(); 

  constructor(private router: Router) { }

  ngOnInit() {
  }

  onAddClick (){
    this.router.navigate ([this.nav]);
  }

}
