import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';

@Injectable({
    providedIn: 'root'
})

export class PrescriberService {


    private path = '/prescribers';

    constructor(private backendService: BackendService) { }

    savePrescriber(provider :any) {
        return this.backendService.post(this.path , provider);

    }

    deletePrescriber(id){
        return this.backendService.post(this.path + '/delete/' + id , {});
    }

    getPrescriberById(id) {        
       
        return this.backendService.get(this.path + '/'+ id);
      }  

      searchPrescriberData(currPage:number, pageSize:number, filter:any) {
    
        var data = {
          pagination: {
            page: currPage,
            size: pageSize
          },
          filter:filter
        };
        return this.backendService.post(this.path + "/search", data);
      }

      savePrescriberAdd(prescriberAddress: any) {
        return this.backendService.post(this.path + '/address', prescriberAddress);
      }  
      savePrescriberContact(prescriberContact: any) {
        return this.backendService.post(this.path + '/contacts', prescriberContact);
      }
     
}


