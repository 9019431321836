import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';

@Injectable({
  providedIn: 'root'
})
export class EorService {


  
  private path = '/eors';

  constructor(private backendService: BackendService) { }

 


  search (currPage, pageSize, filter){
    var data = {
      pagination: {
        page: currPage,
        size: pageSize
      },
      filter:filter
    };

    return this.backendService.post(this.path + "/search", data);
  }


  getAll (){
    return this.backendService.get (this.path);
  }

  
  getById (id){
    return this.backendService.get (this.path +"/" + id);
  }


  save (data){
    return this.backendService.post (this.path , data);
  }

  delete (id){
    return this.backendService.post (this.path + "/delete/" + id, {})
  }

  
}
