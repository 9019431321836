import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { Routes, RouterModule } from '@angular/router';
import { HomeHeaderComponent } from './components/home-header/home-header.component';
import { HomeFooterComponent } from './components/home-footer/home-footer.component'; 
import {SimpleLayoutComponent} from '../containers/simple-layout/simple-layout.component';
import { TemplateEditorComponent } from './template-editor/template-editor.component';
import {FullLayoutComponent} from '../containers/full-layout/full-layout.component';

import { EditorModule } from '@tinymce/tinymce-angular';
import { RbUploaderComponent } from './rb-uploader/rb-uploader.component';
import { SearchHeaderComponent } from './search-header/search-header.component';

import { FormsComponent } from './components/forms/forms.component';
import { FieldComponent } from './components/field/field.component';
import { TextboxComponent } from './components/field/textbox/textbox.component';
import { TextareaComponent } from './components/field/textarea/textarea.component';
import { RadioComponent } from './components/field/radio/radio.component';
import { SelectComponent } from './components/field/select/select.component';
import { FormLayoutComponent } from './components/form-layout/form-layout.component';
import { FormBuilderComponent } from './components/form-builder/form-builder.component';
import {MainMenuComponent} from './main-menu/main-menu.component';
import {MenuFooterComponent} from './menu-footer/menu-footer.component';
import {MenuHeaderComponent} from './menu-header/menu-header.component';
import {MenuNavComponent} from './menu-nav/menu-nav.component';
import {MainBodyComponent} from './main-body/main-body.component';
import { PaginationComponent } from './pagination/pagination.component';
import { TabsComponent } from './tabs/tabs.component';

import { AlertComponent } from './alert/alert.component';
import { UploaderComponent } from './documents/uploader/uploader.component';
import { DocListComponent } from './documents/doc-list/doc-list.component';
import { DocHeaderComponent } from './documents/doc-header/doc-header.component';
import { DocViewerComponent } from './documents/doc-viewer/doc-viewer.component';
import { DocContainerComponent } from './documents/doc-container/doc-container.component';
import { SafeurlPipe, SsnPipe, QdatePipe } from './pipes';
//import { EorComponent } from './eor/eor.component';
import { DatefieldComponent } from './components/datefield/datefield.component';
import { AmountfieldComponent } from './components/amountfield/amountfield.component';
import { PercentfieldComponent } from './components/percentfield/percentfield.component';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { EntitySelectionComponent } from './entity-selection/entity-selection.component';

@NgModule({
  declarations: [
    HomeHeaderComponent, 
    SimpleLayoutComponent,
    HomeFooterComponent, 
    TemplateEditorComponent,
    RbUploaderComponent,
    SearchHeaderComponent,

    FormsComponent,
    FieldComponent,
    TextboxComponent,
    TextareaComponent,
    RadioComponent,
    SelectComponent,
    FormLayoutComponent,
    FormBuilderComponent,
   
    FullLayoutComponent,
    MainMenuComponent,
    MenuFooterComponent,
    MenuHeaderComponent,
    MenuNavComponent,
    MainBodyComponent,
    PaginationComponent,
    TabsComponent,
    AlertComponent,
    QdatePipe,
    SsnPipe,
    UploaderComponent,
    DocListComponent,
    DocHeaderComponent,
    DocViewerComponent,
    DocContainerComponent,
    SafeurlPipe,
    DatefieldComponent,
    AmountfieldComponent,
    PercentfieldComponent,
    EntitySelectionComponent,   
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    RouterModule,
    EditorModule,
    BsDatepickerModule.forRoot()
  ],
  exports: [
    HomeHeaderComponent, 
    HomeFooterComponent,
    SimpleLayoutComponent, 
    RbUploaderComponent ,
    SearchHeaderComponent,
    FormBuilderComponent,
    FullLayoutComponent,
    PaginationComponent,
    TabsComponent,
    AlertComponent,
    QdatePipe,
    SsnPipe,
    SafeurlPipe,
    DocContainerComponent,
    DatefieldComponent,
    AmountfieldComponent,
    PercentfieldComponent, 
    EntitySelectionComponent 
  ]
})
export class SharedModule { }
