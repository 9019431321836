import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service' ;
@Injectable({
  providedIn: 'root'
})
export class ReasonCodeService {

  private path = '/bills';
  constructor(private backendService : BackendService) { }

  saveReasonCode(reason){
    return this.backendService.post(this.path +"/" + reason.billid + "/items/" + reason.itemid + "/reasons" , reason);
  }

  getReasonCode(billid, itemid){
    return this.backendService.get(this.path + '/' +  billid + "/items/" + itemid  + "/reasons" );
  }

  deleteReasonCode(billid, itemid, id) {
    return this.backendService.post(this.path + "/"  + billid + "/items/" + itemid + "/reasons" +  '/delete/' + id, {});
  }

}

