import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-percentfield',
  templateUrl: './percentfield.component.html',
  styleUrls: ['./percentfield.component.scss']
})
export class PercentfieldComponent implements OnInit {

  
  
  @Input() data:any;
  @Input() placeholder:any;
  @Input() maxlength:any;
  @Input() decimal:any; 
  @Output() dataChange = new EventEmitter (); 
  
  constructor() { }

  ngOnInit() {
  }

  onModelChange (e){
    
  }


  onKeyUp (e){

  }

  emit (){
    this.dataChange.emit (this.data);
  }

  onBlur (e){
    if (isNaN(this.data)){
      this.data = "";
      return this.emit ();
    }
    if (this.decimal >0){
      var n = +this.data;
      this.data = n.toFixed (this.decimal);
      return this.emit ();
    }

  }
}
