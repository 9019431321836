import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Routes, RouterModule } from '@angular/router';

 

export const routes: Routes = [
  {
    path: '',
    data: {
      title: 'Admin Dashboard'
    }, 
    redirectTo: 'fee-schedule',
    pathMatch: 'full'
  },  
  {
    path: 'fee-schedule',
    loadChildren: () => import('./modules/fee-schedule/fee-schedule.module').then(m => m.FeeScheduleModule)
  },
  {
    path: 'organization',
    loadChildren: () => import('./modules/organization/organization.module').then(m => m.OrganizationModule)
  }
];


@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      paramsInheritanceStrategy: "always"
    })
  ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
