import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
 
@Component({
  selector: 'app-datefield',
  templateUrl: './datefield.component.html',
  styleUrls: ['./datefield.component.scss']
})
export class DatefieldComponent implements OnInit {


  @Input() data:any;
  @Input() placeholder:any;

  @Output() dataChange = new EventEmitter ();

  bsConfig = { dateInputFormat: 'MM/DD/YYYY' };
  
  constructor() { }

  ngOnInit() {
  }

  onModelChange (e){
    this.dataChange.emit (this.data);
  }

}
