import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';

@Injectable({
  providedIn: 'root'
})
export class BillerService {
 
  private path = '/biller';


  constructor(private backendService: BackendService) { }

  saveBillerData(biller) {

    return this.backendService.post(this.path , biller);
  }

  searchBiller(currPage: number, pageSize: number, filter: any) {

    var data = {
      pagination: {
        page: currPage,
        size: pageSize
      },
      filter: filter
    };
    return this.backendService.post(this.path + "/search", data);

  }

  deleteBillerData(billerid) {

    return this.backendService.post(this.path + '/delete/' + billerid , {});
  }

  getBillerDataById(id) {

    return this.backendService.get(this.path + '/biller/' + id);
  }

  saveBillerAddress(address) {

    return this.backendService.post(this.path + '/address' , address);
  }

  saveBillerContact(contact) {

    return this.backendService.post(this.path + '/contact', contact);
  }


}