import { Injectable } from '@angular/core';
import { HttpResponse, HttpHeaders, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication/authentication.service';
import { Observable } from 'rxjs';
import { tap, catchError } from "rxjs/operators";
@Injectable({
  providedIn: 'root'
})
export class InterceptorService {

  
  constructor(private authenticationService: AuthenticationService,
    private router: Router) {

  }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var authReq = null;
    var isUserLoggedIn = this.authenticationService.isLoggedIn();
    var user = this.authenticationService.getUserDetails();
    var token = this.authenticationService.getAuthToken();

    if (!token && user) {
      token = user.token;
    }
    if (isUserLoggedIn) {
      authReq = req.clone({
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'token': token,
          'appname': 'rb-admin'
        })
      });
    } else {
      authReq = req.clone({
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'appname': 'rb-admin'
        })
      });
    }
    
    return next.handle(authReq).pipe(
      tap(event => {
      //console.log ("Interceptor ... ", event);
      if (event instanceof HttpResponse) {
        //console.log ("Interceptor222 ... ", event.body);
        if (event.body && event.body.status == 'error' && event.body.code == 403) {
          this.router.navigate(['/pages/login']);
          return;
        }
      }
      return event;
    })
    );



  }
}
