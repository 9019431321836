import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';

@Injectable({
    providedIn: 'root'
})
export class WorkQService {

    private path = '/work-q';

    constructor(private backendService: BackendService,
    ) { }

    searchWorkQData(currPage: number, pageSize: number, filter: any) {

        var data = {
            pagination: {
                page: currPage,
                size: pageSize
            },
            filter: filter
        };
        return this.backendService.post(this.path + "/search", data);
    }

    saveWorkQ(data) {
        return this.backendService.post(this.path, data);
    }

    getWorkQDataById(id) {
        return this.backendService.get(this.path + '/' + id);
    }

    deleteWorkQData(workqId) {

        return this.backendService.post(this.path + '/delete/' + workqId, {});
    }
}


