import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment'; 
@Pipe({
  name: 'qdate'
})
export class QdatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    var format = "MM/DD/YYYY";
    if (args){
      format  = args[0];
    }
   // console.log ("changin this value ...", value);
    return moment(value, "MMDDYYYY").format (format);
  }

}
