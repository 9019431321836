import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
 
@Component({
  selector: 'app-doc-list',
  templateUrl: './doc-list.component.html',
  styleUrls: ['./doc-list.component.scss']
})
export class DocListComponent implements OnInit {

  @Input() contextid:any;
  @Input() contexttype: any;
  @Input() docs:any = [];

  @Output() documentClick = new EventEmitter<any>();
  @Output() documentEditClick = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  onDocClick (d){
    this.documentClick.emit (d);
  }

  onDocEditClick (d){
    this.documentEditClick.emit (d);
  }

  getClass (file){

    if (file.extension == "doc" || file.extension == "docx"){
      return "fas fa-file-word";
    }
    if (file.extension == "xls" || file.extension == "xlsx"){
      return "fas fa-file-excel";
    }
    if (file.extension == "ppt" || file.extension == "pptx"){
      return "fas fa-file-powerpoint";
    }
    if (file.extension == "json"){
      return "fas fa-file-code";
    }
    if (file.extension == "pdf"){
      return "fas fa-file-pdf";
    }
    if (file.extension == "csv"){
      return "fas fa-file-csv";
    }
    if (file.mimetype.indexOf ("image") >=0){
      return "fas fa-file-image";
    }

    
    return "fas fa-file";
  }

}
