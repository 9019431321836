import { Injectable } from '@angular/core';
 import {environment} from '../../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CoreService {

  private rootPath = environment.rootPath;

  //private rootPath = 'https://richminds.onlineregistrationforms.com/api/admin';
  
  
  constructor() { }


public getRootPath () {
  return this.rootPath;
}

public isNumeric(evt){
  evt = (evt) ? evt : window.event;
  var charCode = (evt.which) ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
}

public compareFn (a, b){
  if (a && b && a._id && b._id){
   return a._id == b._id;
  }
  return false;
 }

 
public compareCode (a, b){
  if (a && b && a.code && b.code){
   return a.code == b.code;
  }
  return false;
 }



}
