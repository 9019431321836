import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service' ;
@Injectable({
  providedIn: 'root'
})
export class DiagnosisService {

  private path = '/diagnosis';
  constructor(private backendService : BackendService) { }

  saveDiagnosis(diagnosis){
    return this.backendService.post(this.path , diagnosis);
  }

  getDiagnosis(billid){
    return this.backendService.get(this.path + '/' +  billid );
  }

  deleteDiagnosis(id) {
    return this.backendService.post(this.path + '/delete/' + id, {});
  }

}

