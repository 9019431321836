import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private backendService:BackendService) { }

 
  getMenu (){
    return this.backendService.get ("/settings/adminmenu");
  }

}
