import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private path = '/users'; 

  constructor(private backendService: BackendService ) { }

  

  register (data){ 
    return this.backendService.post(this.path + "/register", data);
  }


  confirm (id, sender){ 
    return this.backendService.post(this.path + "/confirm", {id: id, type: sender});
  }

  getAllUsers (){
    return this.backendService.get(this.path );
  }

  searchUsersData(currPage:number, pageSize:number, filter:any) {
    
    var data = {
      pagination: {
        page: currPage,
        size: pageSize
      },
      filter:filter
    };
    return this.backendService.post(this.path + "/search", data);
  }

saveUser(data){
  
  return this.backendService.post(this.path + "/", data);
}

getUserById(id) {    
  return this.backendService.get(this.path + '/' + id);
}


deleteUser(userid) {
    
  return this.backendService.post(this.path + '/delete/', userid);
}


createPassword (data){
  return this.backendService.post(this.path + '/resetpassword', data);
}

forgotPassword (email){
  return this.backendService.post(this.path + '/forgotpassword', {email:email});
}

}
