import { Component, OnInit } from '@angular/core';
import { AuthenticationService} from '../../services'; 

import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.scss']
})
export class HomeHeaderComponent implements OnInit {

  logo:any;
  userInfo: any;

  constructor(private authenticationService: AuthenticationService,
    private ngbDropdownConfig: NgbDropdownConfig,
    private router:Router
    ) { }


  ngOnInit() {
  //  this.ngbDropdownConfig.placement = 'right-bottom';
  this.ngbDropdownConfig.placement = 'left-bottom';
    this.userInfo = this.authenticationService.getUserDetails ();
  }
  onLogo (){
    this.router.navigate  (['/']);
  }

  logout (){
    this.authenticationService.logout ()
    .subscribe (r => {



      this.authenticationService.saveLogin (null);

      this.router.navigate (["/pages/login"]);
    });
  }

}
