import { Component, OnInit, Input } from '@angular/core';
import { CoreService, DocumentService } from '../../services';

@Component({
  selector: 'app-doc-container',
  templateUrl: './doc-container.component.html',
  styleUrls: ['./doc-container.component.scss']
})
export class DocContainerComponent implements OnInit {

  @Input() contextid:any;
  @Input() contexttype: any;
  url:any = "";

  docs:any = [];
  currentDocument: any;

  constructor(private coreService: CoreService, 
              private documentService: DocumentService) { }

  ngOnInit() {
    this.url = this.coreService.getRootPath ()  + "/documents";
    if (this.contextid){
      this.loadDocs ();
    }
  }

  loadDocs (){
    this.documentService.getDocuments (this.contextid, this.contexttype)
    .subscribe (res => {
      this.docs = res;
    })
      
  }

  onDocUpload (e){
    this.loadDocs ();
  }

  onDocumentClick (e){
    this.currentDocument  = e;
  }

  onDocumentEditClick (e){

  }

}
