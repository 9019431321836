import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';

@Injectable({
  providedIn: 'root'
})
export class CaseManagerService {

  constructor(private backendService: BackendService) { }

  private path = '/case-managers';

  saveCaseManagerData(casemanager) {

    return this.backendService.post(this.path , casemanager);
  }

  searchCaseManagerData(currPage: number, pageSize: number, filter: any) {

    var data = {
      pagination: {
        page: currPage,
        size: pageSize
      },
      filter: filter
    };
    return this.backendService.post(this.path + "/search", data);

  }

  deleteCaseManagerData(id) {

    return this.backendService.post(this.path + '/delete/' + id , {});
  }

  getCaseManagerDataById(id) {

    return this.backendService.get(this.path + '/' + id);
  }

  saveCaseManagerAddress(address) {
    return this.backendService.post(this.path + '/address' , address);
  }
 
  saveCaseManagerContact(contact) {

    return this.backendService.post(this.path + '/contacts', contact);
  }
 

}





