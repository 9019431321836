import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service' ;
@Injectable({
  providedIn: 'root'
})
export class ContractsService {

  private path = '/contracts';
  constructor(private backendService : BackendService) { }

  saveContracts(contracts:any){
    return this.backendService.post(this.path + '/save', {contracts : contracts});
  }

  deleteContracts(id) {
    return this.backendService.post(this.path + '/delete/' + id , {});
  }

  searchContracts(currPage:number, pageSize:number, filter:any) {   
    var data = {
      pagination: {
        page: currPage,
        size: pageSize
      },
      filter:filter
    };
    return this.backendService.post(this.path + "/search", data);
  }
  
  getcontractsbyId(id){
    return this.backendService.get(this.path + '/'+ id);
  }

  savePricing(pricing : any){
    return this.backendService.post(this.path + '/pricing', {pricing : pricing});
  }

  getPricingbyId(id){
    return this.backendService.get(this.path + '/get/'+ id);
  }

  getClientType(){
    return this.backendService.get(this.path +  '/get');
  }

}

