import { Injectable } from '@angular/core';
import {PatientService} from '../patient/patient.service';
import {PharmacyService} from '../pharmacy/pharmacy.service';
import {ProviderService} from '../provider/provider.service';
import { PrescriberService } from '../prescribe/prescribe.service';
import { UsersService } from '../users/users.service';
import { ConstantService } from '../constant/constant.service';
import { ClientService } from '../client/client.service';
import { of } from 'rxjs';
import { EmployerService } from '../employer/employer.service';
import { BackendService } from '../backend/backend.service';
import { PayeeService } from '../payee/payee.service';
import { DispenserService } from '../dispenser/dispenser.service';
import { BillerService } from '../biller/biller.service';
import { PharmacistService } from '../pharmacist/pharmacist.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService {


  private path = '/search';


  constructor(private patientService: PatientService,
    private usersService:UsersService,
    private constantService:ConstantService, 
   private pharmacyService: PharmacyService,
   private clientService: ClientService,
   private employerService:EmployerService,
   private backendService: BackendService,
   private providerService: ProviderService,
   private prescriberService: PrescriberService,
   private payeeService: PayeeService,
   private dispenserService: DispenserService,
   private billerService: BillerService,
   private pharmacistService: PharmacistService) { }

  search (entity, term, meta){

    if (entity  == "patient"){
      return this.patientService.searchPatientData (1, 10, {key: term});
    }
    //replace with others.
    if (entity  == "pharmacy"){
      return this.pharmacyService.searchPharmacyData (1, 10, {key: term});
    }

    if (entity  == "provider"){
      return this.providerService.searchProviderData (1, 10, {key: term});
    }
    if (entity  == "ndc" || entity =='icd'){

      var data = {
        key: term,
        page: 1, 
        pagesize: 10,
        type: entity
      };

      return this.backendService.post (this.path, data);
    }

    if (entity  == "prescriber"){

      return this.prescriberService.searchPrescriberData (1, 10, {key: term});
    }

    if (entity  == "dispenser"){

      return this.dispenserService.searchDispenserData (1, 10, {key: term});
    }

    if (entity  == "biller"){

      return this.billerService.searchBiller (1, 10, {key: term});
    }

    if (entity  == "pharmacist"){

      return this.pharmacistService.searchPharmacistData (1, 10, {key: term});
    }

    if (entity  == "payee"){

      return this.payeeService.searchPayee(1, 10, {key: term});
    }

    if (entity == "codeset"){
      return this.constantService.searchConstantValue (term, meta.codesetid);
    }

  }

  






  loadAll (entity, entityname, parent){
    if (entity == "user"){
      return this.usersService.getAllUsers ();
    }else if (entity == "codeset"){
      return this.constantService.getConstantValue(entityname);
    }
    else if (entity == "client"){
      if (parent){
        return this.clientService.getAll (parent._id);
      }else {
        return this.clientService.getAll (null);
      }
     
    }
    else if (entity == "branch"){
      if (!parent){
        return of ([]);
      }
      return this.clientService.getAllBranches(parent._id);
    }
    else if (entity == "state"){
      return this.constantService.getStates();
    }
    
    else if (entity == "employer"){
      return this.employerService.loadAll ();
    }

    //other later..

    return this.usersService.getAllUsers ();
  }

}
