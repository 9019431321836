import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  
  private path = '/documents';

  constructor(private backendService: BackendService) { }

   

  getDocuments  (contextid, contexttype){
    return this.backendService.post (this.path + "/find", {contextid: contextid, contexttype: contexttype});
  }

  getDocumentById  (documentid){
    return this.backendService.get (this.path + "/" + documentid + "/info");
     
  }
  
}
