import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';  
import { SettingsService } from '../services';

@Component({
  selector: 'app-menu-nav',
  templateUrl: './menu-nav.component.html',
  styleUrls: ['./menu-nav.component.scss']
})
export class MenuNavComponent implements OnInit {

  
  menus = [];

  constructor(private router: Router,
              private settingsService:SettingsService) { }

  ngOnInit() {
      this.settingsService.getMenu ()
      .subscribe (res=>{
        this.menus = res["menu"];
        this.makeActive ();
      });
    
  }

  makeActive (){
    if (!this.menus || this.menus.length == 0){
      return;
    }

    var url =  this.router.url.toLowerCase ();
    

   // console.log ("here is url");
    for (var i=0; i<this.menus.length; i++){
      var text = this.menus [i].target.toLowerCase();
      this.menus [i].active = url.indexOf (text) >= 0;
    }

  }

  

  onMenuClick (menu){
    for (var i=0; i<this.menus.length; i++){
      this.menus[i].active = false;
    }

    menu.active = true;

    this.router.navigate ([menu.target]);


  }

}
