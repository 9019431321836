import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  
  private path = '/authenticate';
  private userSessionKey = 'rb-user';

  constructor(private backendService: BackendService,
              private storageService: StorageService ) { }

 

  login(username: string, password: string) {
    return this.backendService.post(this.path + '/login', {email: username, password: password});
  }

  saveLogin (userDetails: any) {
    this.storageService.save (this.userSessionKey, userDetails);
  }

  isLoggedIn () {
    const user = this.storageService.get (this.userSessionKey); 
    return  user && user.token; 
  }

  me () {
    return this.storageService.get (this.userSessionKey);
  }

  getAuthToken () {
    const user = this.storageService.get (this.userSessionKey);
    if (!user) {
      return null;
    } else {
      return user.token;
    }
  }

  logout () {
    return this.backendService.get(this.path + '/logout');
  }

  getUserDetails(){
     return this.storageService.get(this.userSessionKey);
  }
}
