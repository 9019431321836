import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  constructor(private backendService: BackendService) { }

  private path = '/patients';

  savePatientData(patient) {

    return this.backendService.post(this.path , patient);
  }

  searchPatientData(currPage: number, pageSize: number, filter: any) {

    var data = {
      pagination: {
        page: currPage,
        size: pageSize
      },
      filter: filter
    };
    return this.backendService.post(this.path + "/search", data);

  }

  deletePatientData(patientId) {

    return this.backendService.post(this.path + '/delete/' + patientId , {});
  }

  getPatientDataById(id) {

    return this.backendService.get(this.path + '/' + id);
  }

  savePatientAddress(address) {
console.log("address>>",address);
    return this.backendService.post(this.path + '/address' , address);
  }
 
  savePatientContact(contact) {

    return this.backendService.post(this.path + '/contacts', contact);
  }
 

}





