import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';

@Injectable({
  providedIn: 'root'
})
export class PharmacyService {

  private path = '/pharmacy';

  constructor(private backendService: BackendService,
  ) { }




  searchPharmacyData(currPage: number, pageSize: number, filter: any) {

    var data = {
      pagination: {
        page: currPage,
        size: pageSize
      },
      filter: filter
    };
    return this.backendService.post(this.path + "/search", data);
  }

  savePharmacy(pharmacy) {
    return this.backendService.post(this.path, pharmacy);
  }
  savePharmacyAdd(address) {
    return this.backendService.post(this.path + '/address', address);
  }
  savePharmacyContact(contact) {
    return this.backendService.post(this.path + '/contact', contact);
  }
  getPharmacyById(id) {
    return this.backendService.get(this.path + '/' + id);
  }

  deletePharmacy(pharmacyid) {

    return this.backendService.post(this.path + '/delete/' + pharmacyid , {});
  }
}


