import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';

@Injectable({
  providedIn: 'root'
})
export class PayeeService {

  private path = '/payees';
  constructor(private backendService: BackendService) { }

  

  savePayeeData(payee) {

    return this.backendService.post(this.path , payee);
  }

  searchPayee(currPage: number, pageSize: number, filter: any) {

    var data = {
      pagination: {
        page: currPage,
        size: pageSize
      },
      filter: filter
    };
    return this.backendService.post(this.path + "/search", data);

  }

  deletePayeeData(payeeId) {

    return this.backendService.post(this.path + '/delete/' + payeeId , {});
  }

  getPayeeDataById(id) {

    return this.backendService.get(this.path + '/' + id);
  }

  savePayeeAddress(address) {

    return this.backendService.post(this.path + '/address' , address);
  }
 
  savePayeeContact(contact) {

    return this.backendService.post(this.path + '/contacts', contact);
  }

}
