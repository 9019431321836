import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service'; 

@Injectable({
  providedIn: 'root'
})

export class ContactService {

  
  private path = '/contacts';


  constructor(private backendService: BackendService) { }

   
 
  
  

  saveContact(data){
    return this.backendService.post(this.path , data);
  }

  getContacts(cid){
    
    return this.backendService.get(this.path + '/entities/' +  cid );
  }
  getContactsById(id){
    
    return this.backendService.get(this.path +  id );
  }
 

  deleteContact(id) {
   
    return this.backendService.post(this.path + '/remove/'+ id, {});
  }
 
 

 

 
 


}