import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-doc-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent implements OnInit {


  @Input() contextid:any;
  @Input() contexttype: any;

  
  @Input() url:any;
  @Input() msg: any;
  @Input() doctype:any;
  @Input() dropZoneId:any;

  
  @Output() docUploaded = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
  }
  
  onDocUpload (e){
    this.docUploaded.emit (e);
  }

}
