import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';
import { of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ConstantService {

  private path = '/constant';
  states = [];
  constructor(private backendService: BackendService) { }


  getStates() {

    // if (this.states.length > 0){
    //   return of (this.states);
    // }
    return this.backendService.get('/geo/states');
       
  }

  getConstantValue(code) {

    return this.backendService.get(this.path + '/code/' + code);
  }


   

  getConstant() {
    return this.backendService.get(this.path);
  }

  saveConstantValue(data) {

    return this.backendService.post(this.path + '/value/save', data);

  }

  saveConstant(data) {

    return this.backendService.post(this.path + '/save', data);
  }

  getConstantValueByConstantId(constantid) {
    return this.backendService.get(this.path + '/' + constantid);

  }

  deleteConstantValue(id) {
    return this.backendService.post(this.path + '/delete/' , id);
  }

  deleteConstant(id) {
    return this.backendService.post(this.path + '/remove/' , id);
  }

  searchConstant(search) {
    return this.backendService.post(this.path + '/searchconst', { search: search });
  }


  findContantByCode(code) {
    return this.backendService.get(this.path + '/find/'+ code);
  }

  searchConstantValue(search, constantid) {
    return this.backendService.post(this.path + '/searchconstval', { search: search, constantid: constantid });
  }
}

