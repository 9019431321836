import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss']
})
export class FormsComponent implements OnInit {

  @Input() form: any;
  constructor() { }

  ngOnInit() {

  }

  getClass (){
    if (!this.form){
      return 'col-md-12';
    }
    if (this.form.cols <=0 || !this.form.cols){
      this.form.cols = 1;
    }
    return 'col-md-' + (12/this.form.cols);
  }

}
