import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})

export class ClientService {


  private path = '/clients';


  constructor(private backendService: BackendService) { }


  saveClientDetail(client) {

    return this.backendService.post(this.path + "/general", client);
  }

  getAll (parentId){
     
      return this.backendService.post(this.path + "/all", {parent:parentId} );
    
  }

  search(currPage: number, pageSize: number, filter: any) {

    var data = {
      pagination: {
        page: currPage,
        size: pageSize
      },
      filter: filter
    };
    return this.backendService.post(this.path + "/search", data);
  }



  deleteClientData(clientId) {

    return this.backendService.post(this.path + '/delete/', clientId);
  }

  getClientDataById(id) {

    return this.backendService.get(this.path + '/' + id);
  }

  saveAddress(address) {
    return this.backendService.post(this.path + '/address', address);
  }

  getAddress(cid) {

    return this.backendService.get(this.path + '/' + cid + '/address');
  }

  saveContact(contact) {
    return this.backendService.post(this.path + '/contact', contact);
  }

  deleteClientAddress(id) {
    return this.backendService.post(this.path + '/deleteaddress/', id);
  }

  deleteClientContacts(id) {
    return this.backendService.post(this.path + '/deletecontact/', id);
  }




  getContacts(cid) {

    return this.backendService.get(this.path + '/' + cid + '/contacts');
  }


  /** all branches */

  getBranches(clientId) {
    return this.backendService.get(this.path + '/' + clientId + "/branch");

  }

  getAllBranches(clientId) {
    return this.backendService.get(this.path + '/' + clientId + "/branch/all");

  }

  getBranch(id) {
    return this.backendService.get(this.path + '/branch/' + id);

  }

  saveBranch(branch) {
    return this.backendService.post(this.path + '/branch', branch);

  }

  deleteBranchData(id) {
    return this.backendService.post(this.path + '/deletebranch/', id);
  }


  /** branch ends */
  getContractByClientId(clientid) {
    return this.backendService.get(this.path + '/get/' + clientid);

  }




}