import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy, CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {PagesModule} from './pages/pages.module'; 




import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import {
  CoreService,
  BackendService,
  AuthenticationService,
  InterceptorService,
  SettingsService,
  StorageService,
  AuthguardService,
  AlertService,
  AddressService,
  ContactService,
  DocumentService,
  EorService

} from './shared';




@NgModule({
  declarations: [
    AppComponent,
   
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    PagesModule
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
  
    CoreService,
    BackendService,
    AuthenticationService,
    SettingsService,
    StorageService,
    AuthguardService,
    AlertService,
    AddressService,
    ContactService,
    DocumentService,
    EorService

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
