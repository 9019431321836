import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';

@Injectable({
    providedIn: 'root'
})
export class OrganizationService {

    private path = '/organizations';
    constructor(private backendService: BackendService,
    ) { }
    
    getOraganization() {
        return this.backendService.get(this.path);
    }

    searchOrganization(currPage:number, pageSize:number, filter:any) {   
        var data = {
          pagination: {
            page: currPage,
            size: pageSize
          },
          filter:filter
        };
        return this.backendService.post(this.path + "/search", data);
      }

      getOrganizationById(id){
        return this.backendService.get(this.path + '/'+ id);
      }  

      getUsers(orgId){
        return this.backendService.get(this.path + '/'+ orgId + "/users");
      }  


      getPermissions(orgId){
        return this.backendService.get(this.path + '/'+ orgId + "/permissions");
      }  

      toggleMenu(orgId, menuId){
        return this.backendService.post(this.path + '/'+ orgId + "/permissions/toggle", {orgid: orgId, menu: menuId});
      }  

      toggleUser (orgId, userid){
        return this.backendService.post(this.path + '/'+ orgId + "/users/toggle", {orgid: orgId, userid: userid});
    
      }

}


