import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'qssn'
})
export class SsnPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    var n = 4;
    if (value && value.length >n){
      var s  =  "";
      for (var i=0; i<value.length; i++){
        if (i<value.length - n){
          s += "x";
        }else {
          s += value[i];
        }
      }
      return s;
    }
    return null;
  }

}
