import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';

@Injectable({
  providedIn: 'root'
})


export class PharmacistService {

  private path = '/pharmacist';


  constructor(private backendService: BackendService) { }

  savePharmacistData(pharmacist) {

    return this.backendService.post(this.path, pharmacist);
  }

  searchPharmacistData(currPage: number, pageSize: number, filter: any) {

    var data = {
      pagination: {
        page: currPage,
        size: pageSize
      },
      filter: filter
    };
    return this.backendService.post(this.path + "/search", data);

  }

  deletePharmacistData(pharmacistid) {

    return this.backendService.post(this.path + '/delete/' + pharmacistid, {});
  }

  getPharmacistDataById(id) {

    return this.backendService.get(this.path + '/' + id);
  }

  savePharmacistAddress(address) {

    return this.backendService.post(this.path + '/address', address);
  }

  savePharmacistContact(contact) {

    return this.backendService.post(this.path + '/contact', contact);
  }


}

