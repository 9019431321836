import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  
  path = '/profile';

  constructor(
    private backendService: BackendService
  ) { }

  saveProfile(profile){
    console.log("profile service>>>>>>>>>>>>>>",profile);
    return this.backendService.post(this.path , profile);
  }

  getProfileById(){
    return this.backendService.get(this.path + '/get');
  }
}
