import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { SearchService } from '../services';

@Component({
  selector: 'app-entity-selection',
  templateUrl: './entity-selection.component.html',
  styleUrls: ['./entity-selection.component.scss']
})
export class EntitySelectionComponent implements OnInit, OnChanges {

  @Input() data;
  @Input() entity;
  @Input() entityname;
  @Input () parent; 
  @Input() cacheable = true;
  @Output() dataChange = new EventEmitter(); 
  dataLoaded   = false;
  results:any = [];

  constructor(private searchService:SearchService) { }

  ngOnInit() {
    
  }

  ngOnChanges (){
    if (this.entity   && !this.dataLoaded){
      
      this.searchService.loadAll (this.entity, this.entityname, this.parent)
      .subscribe(res=>{
        this.results = res || [];
        if (this.cacheable){ //not dependent.
         this.dataLoaded =  true;
        }
        if (this.results.length == 0)       {
          this.data = null;          
          this.dataChange.emit (this.data);
        }else if (this.results.length == 1 && !this.data)       {
          this.data = this.results[0];          
          this.dataChange.emit (this.data);
        }
      });       
    }
  }

  onSelectionChanged (e){
    this.data = e;
    this.dataChange.emit (this.data);
  }

  compareFn (a, b){
    if (a && b && a._id && b._id){
     return a._id == b._id;
    }
    return false;
   }

   getName (s){
     if (!s){
       return "";
     }
     if (s.name){
       return s.name;
     }

     if (s.firstname){
       return s.firstname + " " + s.lastname;
     }
   }
 

}
