import { Component, OnInit, Input, Output } from '@angular/core'; 
import { Route, Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {


  @Input () tabData:any;
  url:any;

  constructor(private route: Router) { }

  ngOnInit() {

    this.url = this.route.url;
    if (this.url.indexOf("/") == 0){
      this.url = this.url.substr (1);
    }

    this.activate ();

    this.route.events.subscribe (e =>{
      if (e instanceof NavigationEnd){
        this.url = this.route.url;
        if (this.url.indexOf("/") == 0){
          this.url = this.url.substr (1);
        }

        this.activate  ();

        
      
      }
    });
    
    

  }

  onTabClick (t){
    this.route.navigate ([t.href]);
  }

  activate (){
    if (!this.tabData){
      return;
    }
    var found = false;
    for (var i=0; i<this.tabData.length; i++){
      var t = this.tabData [i];
      t.active = t.href == this.url;
      if (t.active){
        found = true;
      }
    }

    if (!found){
      this.tabData [0].active = true;
    }

   // console.log ("here is tab ..", this.tabData, this.url);


  }

}
